import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Aboutwork from '../views/Aboutwork.vue'

Vue.use(VueRouter)

  const routes = [
    {
      path: '/',
      name: 'Home',
      // component: Home
      component: () => import(/* webpackChunkName: "about" */ '../views/Comingsoon.vue'),
    },
    {
      path: '/fvl-fest-2024',
      name: 'fvl-fest-2024',
      component: () => import(/* webpackChunkName: "about" */ '../views/Comingsoon.vue'),
  
    },
  {
    path: '/Fvlhome',
    name: 'Fvlhome',
    component: () => import(/* webpackChunkName: "about" */ '../views/Fvlhome.vue'),

  },
  {
    path: '/Fvlhome_en',
    name: 'Fvlhome_en',
    component: () => import(/* webpackChunkName: "about" */ '../views/Fvlhome_en.vue'),

  },
  {
    path:'/Fvlhome/Aboutwork/:id/:title/:word/:photo',
    name:'Aboutwork',
    component: () => import(/* webpackChunkName: "about" */ '../views/Aboutwork.vue')
  },
  // {
  //   path: '/Aboutwork',
  //   name: 'Aboutwork',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/Aboutwork.vue')
  // },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/News',
    name: 'News',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/News.vue')
  },

  {
    path: '/All',
    name: 'All',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/All.vue')
  },
  {
    path: '/2018',
    name: '2018',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/2018.vue')
  },
  {
    path: '/2019',
    name: '2019',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/2019.vue')
  },
  {
    path: '/2020',
    name: '2020',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/2020.vue')
  },
  {
    path: '/2021',
    name: '2021',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/2021.vue')
  },
  {
    path: '/2022',
    name: '2022',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/2022.vue')
  },
  {
    path: '/2023',
    name: '2023',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/2023.vue')
  },
  {
    path: '/2024',
    name: '2024',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/2024.vue')
  },
  {
    path: '/collection',
    name: 'Collection',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Collection.vue')
  },
  
  
  
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
